import React, { useEffect, useState } from "react";
import ai_img from "../../assets/img/ai_img.svg";
import cross_img from "../../assets/img/cross_img.svg";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import { getProjectList } from "../../redux/GetProjectListSlice";
import { useDispatch, useSelector } from "react-redux";
import PdfFileUpload from "../PdfFileUpload/PdfFileUpload";
import { deletefile } from "../../redux/DeleteFileSlice";

const fileTypes = ["PDF"];

const ProjectEdit = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [projectId, setProjectId] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [currentProject, setCurrentProject] = useState(null);
  const [pdfListinCurrentProject, setPdfListinCurrentProject] = useState([]);
  const [file, setFile] = useState(null);
  const [showUploader, setShowUploader] = useState(false);
  // const [showOptionList, setShowOptionList] = useState(true);
  const projectResponse = useSelector(
    (state) => state.getProjectListReducer.data
  );

  const [rowId, setRowId] = useState("");

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (id) => {
    setRowId(id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const deleteResponse = useSelector((state) => state.DeleteFileReducer.data);
  // useEffect(() =>{
  //   dispatch( DeletePdfFromProject());
  // // },[]);

  useEffect(() => {
    dispatch(getProjectList());
  }, []);

  useEffect(() => {
    // console.log("Project Response ===> ", projectResponse);
    if (projectResponse != null && projectResponse.status == 1) {
      setProjectList(projectResponse.data);
      if (projectId == "" && projectResponse.data.length > 0) {
        setProjectId(projectResponse.data[0]._id);
      }
    }
  }, [projectResponse]);

  const getCurrentProjectFromId = (prodjcetId) => {
    const result = projectList.find((project) => project._id === prodjcetId);
    return result;
  };

  useEffect(() => {
    if (projectId) {
      setCurrentProject(getCurrentProjectFromId(projectId));
    }
  }, [projectId, projectList]);

  const onProjectSelect = (selectedProjectId) => {
    setProjectId(selectedProjectId);
  };

  useEffect(() => {
    if (currentProject?.ProjectChat) {
      const pdfList = currentProject?.ProjectChat.map((item) => item);

      setPdfListinCurrentProject(pdfList);
    }
  }, [currentProject]);

  useEffect(() => {
    console.log("deleteResponse ===>", deleteResponse);
    if (deleteResponse != null && deleteResponse.status == 1) {
      dispatch(getProjectList());
    }
  }, [deleteResponse]);

  // console.log("currentProject", currentProject);
  // console.log("pdfListinCurrentProject", pdfListinCurrentProject);

  const handlePdfUploadSuccess = (newPdf) => {
    dispatch(getProjectList());
    getCurrentProjectFromId(projectId);
    const updatedPdfList = [
      ...pdfListinCurrentProject,
      { id: newPdf._id, pdfName: newPdf.chatName },
    ];
    setPdfListinCurrentProject(updatedPdfList);
  };

  const data = [
    {
      name: "STAND NCC2016-BCA-Guide.pdf",
      createdAt: "05.15.2024",
      project: "Magic Project",
      path: "STAND NCC2016-BCA-Guide.pdf",
    },
    {
      name: "STAND NCC2016-BCA-Guide.pdf",
      createdAt: "05.15.2024",
      project: "Black Project",
      path: "STAND NCC2016-BCA-Guide.pdf",
    },
    {
      name: "STAND NCC2016-BCA-Guide.pdf",
      createdAt: "05.15.2024",
      project: "Magic Project",
      path: "STAND NCC2016-BCA-Guide.pdf",
    },
    {
      name: "STAND NCC2016-BCA-Guide.pdf",
      createdAt: "05.15.2024",
      project: "Black Project",
      path: "STAND NCC2016-BCA-Guide.pdf",
    },
  ];

  // console.log("currentProject", currentProject);
  // console.log("pdfListinCurrentProject", pdfListinCurrentProject);
  // console.log("projectList", projectList);
  // console.log("pdfListinCurrentProject", pdfListinCurrentProject);

  function DeletePdfFromProject(id) {
    dispatch(deletefile({ projectChatId: id }));
    handleCloseModal();

    console.log("id", rowId);
  }

  if (projectList.length == 0) {
    return (
      <div className="sidebar_mar">
        <Header />
        <h2>Projects</h2>
        <div style={{ marginTop: 20, textAlign: "center" }}>
          No Project Found
        </div>
        {/* <Button>Create Project</Button> */}
      </div>
    );
  }
  return (
    <div className="sidebar_mar">
      <Header />
      <h2>Projects</h2>

      <div className="project_btn_styles">
        <div className="flex gap-5 itemcenter">
          <h4 className="text-nowrap" style={{ textWrap: "nowrap" }}>
            Project Name:
          </h4>

          <select
            className="project-select"
            value={projectId}
            onChange={(e) => onProjectSelect(e.target.value)}
          >
            {projectList.map((project) => (
              <option key={project._id} value={project._id}>
                {project.projectName}
              </option>
            ))}
          </select>
        </div>
        <Button
          type="button"
          onClick={() => navigation(`/project/edit/${currentProject?._id}`)}
        >
          Edit Project
        </Button>
      </div>

      <div className="project_edit_content">
        <div className="label_input_flex">
          <h4>
            {currentProject?.companyName ? currentProject?.companyName : "N/A"}
          </h4>
          <p>Company</p>
        </div>
        <div className="label_input_flex">
          <h4>
            {currentProject?.streetAddress},{currentProject?.city},
            {currentProject?.state},{currentProject?.country},
            {currentProject?.zipCode}
          </h4>
          <p>Location</p>
        </div>
        <div className="label_input_flex">
          <h4>{pdfListinCurrentProject?.length}</h4>
          <p>Indexed documents</p>
        </div>
        <div className="label_input_flex">
          <h4>
            {(() => {
              if (currentProject?.ProjectChat.length === 0) {
                return "--";
              }
              const date = new Date(
                currentProject?.ProjectChat[
                  currentProject?.ProjectChat.length - 1
                ].createdAt
              );
              const formattedDate =
                ("0" + (date.getMonth() + 1)).slice(-2) +
                "." +
                ("0" + date.getDate()).slice(-2) +
                "." +
                date.getFullYear();
              return formattedDate;
            })()}
          </h4>
          <p>Document last added</p>
        </div>

        {/* <div className="label_input_flex">
          <h4>2</h4>
          <p>Currently indexing documents</p>
        </div>

        <div className="label_input_flex">
          <h4>0</h4>
          <p>Failed to index documents</p>
        </div> */}
      </div>

      {/* <Button
        type="button"
        style={{ marginTop: "20px" }}
        onClick={() => setShowUploader(!showUploader)}
      >
        {!showUploader ? "Upload Documents" : "Close"}
      </Button>
      {showUploader && ( */}
      <div className="document_bg">
        <div className="document-option-item">
          <label className="label_text">Upload the file for project</label>
          <PdfFileUpload
            projectId={projectId}
            // setShowOptionList={showOptionList}
            onPdfUploadSuccess={handlePdfUploadSuccess}
          />
        </div>
      </div>
      {/* )} */}

      {pdfListinCurrentProject.length === 0 ? (
        <h4 style={{ marginTop: "40px", textAlign: "center" }}>
          No documents found
        </h4>
      ) : (
        <>
          <h4 style={{ margin: "40px 0" }}>List of Documents</h4>
          <div className="table_style">
            <table className="data-table">
              <thead>
                <tr>
                  <th className="text_center">Relevance</th>
                  <th>Name</th>
                  <th>Created At</th>
                  <th className="text_center">Source</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {pdfListinCurrentProject.map((row, index) => (
                  <tr key={index}>
                    <td className="text_center">
                      <b>-</b>
                    </td>
                    <td>{row.chatName}</td>
                    <td className="color_gray"> {row.createdAt}</td>
                    <td className="text_center">
                      <img src={ai_img} alt="ai_img" />
                    </td>
                    <td className="text_center">
                      <img
                        onClick={() => handleOpenModal(row._id)} //DeletePdfFromProject(row._id)
                        src={cross_img}
                        alt="cross_img"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="your_ai_btn">
            <Button
              onClick={() =>
                navigation("/TaskAi", {
                  state: {
                    projectId,
                  },
                })
              }
            >
              Go to Your Ai
            </Button>
          </div>

          {showModal && (
            <div className="modal">
              <div className="modal-content">
                <h2 style={{ marginTop: -5 }}>Your Ai!</h2>
                <p style={{ marginTop: 16 }}>Do you really want to delete?</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    style={{
                      marginTop: 16,
                      backgroundColor: "red",
                      color: "white",
                    }}
                    onClick={handleCloseModal}
                  >
                    Close
                  </Button>
                  <Button
                    style={{
                      marginTop: 16,
                      marginLeft: 16,
                      backgroundColor: "green",
                      color: "white",
                    }}
                    onClick={() => DeletePdfFromProject(rowId)}
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProjectEdit;

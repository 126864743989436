import React, { useState } from 'react';
import ai_img from "../../assets/img/img-logo.svg";
import { Link } from 'react-router-dom';
import facebook_black from "../../assets/img/facebook_black.svg";
import instagram from "../../assets/img/instagram.svg";
import twitter from "../../assets/img/twitter.svg";
import linkedin from "../../assets/img/linkedin.svg";


function Footer() {

    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true);
        } else if (scrolled <= 300) {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    window.addEventListener("scroll", toggleVisible);
    return (
        <>
            <footer className="footer">
                <div className='coustom_container'>
                    <div className="footer-container">
                        <div className="footer-logo">
                        <img src={ai_img} alt="Logotype" className="logo_footer" />
                            {/* <h3>
                                <img src={ai_img} alt="Logotype" className="logo_footer" />
                            </h3> */}
                            <p className="vision">
                                Our vision is to provide convenience and help increase your sales business.
                            </p>
                            <div className='images_flex'>
                                <img src={facebook_black} alt='facebook_black' />
                                <img src={instagram} alt='instagram' />
                                <img src={twitter} alt='twitter' />
                                <img src={linkedin} alt='linkedin' />
                            </div>
                        </div>
                        <div className="footer-links">
                            <div className="footer-column">
                                <h3>About</h3>
                                <ul>
                                    <li><Link to="/how_work" onClick={() => scrollToTop()}>How it works</Link></li>
                                    <li><Link to="/feature" onClick={() => scrollToTop()}>Featured</Link></li>
                                    <li><Link to="#">Partnership</Link></li>
                                    <li><Link to="/business_relation" onClick={() => scrollToTop()}>Business Relation</Link></li>
                                </ul>
                            </div>
                            <div className="footer-column">
                                <h3>Community</h3>
                                <ul>
                                    <li><Link to="#">Events</Link></li>
                                    <li><Link to="#">Blog</Link></li>
                                    <li><Link to="#">Podcast</Link></li>
                                    <li><Link to="#">Invite a friend</Link></li>
                                </ul>
                            </div>
                            <div className="footer-column">
                                <h3>Socials</h3>
                                <ul>
                                    <li><Link to="#">LinkedIn</Link></li>
                                    <li><Link to="#">Instagram</Link></li>
                                    <li><Link to="#">Twitter</Link></li>
                                    <li><Link to="#">Facebook</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom"> 
                        <p style={{ fontSize: 12, color: '#636363', fontWeight: 400 }}>©2024 Company Name. All rights reserved</p>
                        <div className="footer-bottom-links">
                            <Link to="/privacy_policy" style={{ fontSize: 12, color: '#0F0F0F', fontWeight: 500 }} onClick={() => scrollToTop()}><b>Privacy & Policy</b></Link>
                            <Link to="/term_conditon" style={{ fontSize: 12, color: '#0F0F0F', fontWeight: 500 }} onClick={() => scrollToTop()}><b>Terms & Conditions</b></Link>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;

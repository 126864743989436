// src/redux/slices/authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl, facebookLoginApi } from "../utils/Constants";

export const facebookLogin = createAsyncThunk("facebookLogin", async (payload) => {
  try {
    console.log("payload Question ===>", payload);

    const response = await axios.post(ApiBaseUrl + facebookLoginApi, payload);
    console.log("faceeeeeeeeeeeeeeeeeebooook ===>", response.data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
});

const FacebookLoginSlice = createSlice({
  name: "facebookLoginReducer",

  initialState: {
    isLoading: false,
    data: null,
  },
  reducers: {
    clearFacebookLoginData: (state) => {
      state.data = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(facebookLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(facebookLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(facebookLogin.rejected, (state) => {
        state.isError = false;
      });
  },
});

export const { clearFacebookLoginData } = FacebookLoginSlice.actions;
export default FacebookLoginSlice.reducer;

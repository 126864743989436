/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Fuse from "fuse.js";
import crossIcon from "../../assets/img/Icon.svg"

const drawerStyles = (isOpen) => css`
  position: fixed;
  bottom: 100px;
  right: ${isOpen ? "0" : "-100%"};
  width: 600px;
  height: 60vh;
  background: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  transition: right 0.4s ease-in-out;
  z-index: 1000;
  overflow-y: auto;
`;
  // padding: 40px 10px 10px 10px;
  // border-top-left-radius: 10px;
  // border-bottom-left-radius: 10px;

const closeButtonStyles = css`
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  border: none;
  cursor: pointer;
  padding: 6px 12px;
  font-size: 15px;
  z-index: 1009;
`;
// background: red;
const PdfDrawer = ({ pdfUrl, isOpen, onClose, textData, openAiResponse }) => {
  const [keywords, setKeywords] = useState([]);
  const [isDocumentLoaded, setDocumentLoaded] = useState(false);

  useEffect(() => {
    if (openAiResponse) {
      const preparedKeywords = prepareKeywords(openAiResponse);
      setKeywords(preparedKeywords);
    }
  }, [openAiResponse]);

  const handleDocumentLoad = () => setDocumentLoaded(true);

  // Function to prepare keywords from OpenAI response
  const prepareKeywords = (textData) => {
    if (!textData) return [];

    const cleanedText = textData.replaceAll('"', "").trim();
    const splitArr = cleanedText.split(/\s+/);

    // Chunk the keywords into groups of 5 words
    const trimmedArray = [];
    const trimCount = 5;

    for (let i = 0; i < splitArr.length; i += trimCount) {
      trimmedArray.push(splitArr.slice(i, i + trimCount).join(" "));
    }

    // Handle edge case where last chunk has only one word
    if (
      trimmedArray.length > 1 &&
      trimmedArray[trimmedArray.length - 1].split(" ").length === 1
    ) {
      trimmedArray[trimmedArray.length - 2] += ` ${trimmedArray.pop()}`;
    }

    return trimmedArray;
  };

  const fuzzySearch = (text, keywords) => {
    const fuse = new Fuse([text], {
      includeScore: true,
      threshold: 0.7, // Lower threshold for stricter matching
      distance: 100, // Control match sensitivity based on word distance
    });

    return keywords.flatMap((keyword) =>
      fuse.search(keyword).map((match) => match.item)
    );
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      searchPlugin: {
        keyword: keywords,
        onHighlightKeyword: (props) => {
          props.highlightEle.style.backgroundColor = "rgba(255,240,0,0.2)";
        },
      },
    },
  });

  return (
    <div css={drawerStyles(isOpen)}>
      <button css={closeButtonStyles} onClick={onClose}>
        <img src={crossIcon} alt="cross icon" />
      </button>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]} />
      </Worker>
    </div>
  );
};

export default PdfDrawer;

import React, { useEffect, useState } from "react";
import ai_img from "../../assets/img/img-logo.svg";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import next_icon from "../../assets/img/next_icon.svg";
import { useNavigate } from "react-router-dom";
import google from "../../assets/img/google.svg";
import facebook from "../../assets/img/facebook.svg";
import { useDispatch, useSelector } from "react-redux";
import { clearDataSignUp, signupUser } from "../../redux/signupSlice";
import { useGoogleLogin } from "@react-oauth/google";
import {
  clearGoogleLoginData,
  googleLogin,
} from "../../redux/GoogleSignInSlice";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { VisibilityTwoTone, VisibilityOffTwoTone } from "@mui/icons-material";

import { clearFacebookLoginData, facebookLogin } from "../../redux/FacebookSignInSlice";

const SignUp = () => {
  const [phnNumber, setPhnNumber] = useState("");
  const [email, setEmail] = useState("");
  const [inputMode, setInputMode] = useState("phone"); // 'phone' or 'email'
  const [from, setFrom] = useState();
  const [fromScreen, setFromScreen] = useState(2);
  const [country, setCountry] = useState("61");
  const [active, setaActive] = useState(0);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();


  const handleOnChange = (value) => {
    setCountry(value);
  };

  const navigation = useNavigate();
  const dispatch = useDispatch();

  const onNextClick = () => {
    let payload = {};
    if (inputMode === "phone") {
      if (!phnNumber) {
        alert("Please enter a valid phone number!")
        return
      }
      payload = {
        type: 2,
        countryCode: "+" + country,
        mobileNumber: phnNumber,
        signUpWithMobileOrEmail: inputMode === "phone" ? 1 : 2,
        email: inputMode === "email" ? email : undefined,
      };
    } else if (inputMode === "email") {
      if (!password || !confirmPassword || !email) {
        alert("Please enter required fields: Email, Password, Confirm Password")
        return
      }
      if (password === confirmPassword) {
        payload = {
          type: 2,
          mobileNumber: email,
          signUpWithMobileOrEmail: 2,
          email: inputMode === "email" ? email : undefined,
          password: password,
        };
      } else {
        alert("Password does not match");
        return
      }
    }

    dispatch(signupUser(payload));
  };

  const [id, setId] = useState();

  const signInResponse = useSelector((state) => state.signupReducer.data);
  const googleLoginResponse = useSelector(
    (state) => state.googleLoginReducer.data
  );

  const facebookLoginResponse = useSelector(
    (state) => state.facebookLoginReducer.data
  );

  const handleFacebookLogin = () => {
    window.FB.login(
      function (response) {
        if (response.authResponse && response.status === 'connected') {
          console.log("Facebook Login Success:", response);
          const payload = {
            fbId: response.authResponse.accessToken,
          };
          dispatch(facebookLogin(payload));

        } else {
          alert("User cancelled login or did not fully authorize.");
          return 
        }
      },
      { scope: "public_profile,email"}
    );
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const payload = {
        googleId: tokenResponse.access_token,
      };
      dispatch(googleLogin(payload));
    },
  });

  // useEffect(() => {
  //   console.log("0000000000000000000000  ")
  //   if (signInResponse != null && signInResponse.status === 1) {
  //     setFrom(0);
  //     setId(signInResponse.data._id);
  //   } else {
  //     if (signInResponse != null) {
  //       alert(signInResponse.message);
  //     }
  //   }
  // }, [signInResponse]);

  // useEffect(() => {
  //   console.log("11111111111111111111111111  ")
  //   if (
  //     signInResponse != null &&
  //     signInResponse.otp_required === 0 &&
  //     signInResponse.login_type == "email"
  //   ) {
  //     setFrom(1);
  //     setId(signInResponse.data._id);
  //     alert("Registered Successfully Please Login");
  //   } else {
  //     if (signInResponse != null) {
  //       alert(signInResponse.message);
  //     }
  //   }
  // }, [signInResponse]);

  useEffect(() => {
    if (signInResponse) {
      if (signInResponse.status === 1 && (signInResponse.otp_required === 'undefined' || signInResponse.otp_required !== 0)) {
        setFrom(0);
        setId(signInResponse.data._id);
      } else if (signInResponse.otp_required === 0 && signInResponse.login_type === "email") {
        setFrom(1);
        setId(signInResponse.data._id);
        alert("Registered Successfully Please Login");
        // if (!sessionStorage.getItem("signup_alert_shown")) {
        //   alert("Registered Successfully Please Login");
        //   sessionStorage.setItem("signup_alert_shown", "true");
        // }
      } else {
        console.log('from sign up js', sessionStorage.getItem("signup_alert_shown"))
        alert(signInResponse.message);
        dispatch(clearDataSignUp());
        // if (!sessionStorage.getItem("signup_alert_shown")) {
        //   alert(signInResponse.message);
        //   sessionStorage.setItem("signup_alert_shown", "true");
        // }
      }
    }
  }, [signInResponse, dispatch]);

  useEffect(() => {
    if (facebookLoginResponse != null && facebookLoginResponse.status === 1) {
      setFrom(2);
      localStorage.setItem("token", facebookLoginResponse.token);
      setId(facebookLoginResponse.data._id);

    }
  }, [facebookLoginResponse]);

  useEffect(() => {
    if (googleLoginResponse != null && googleLoginResponse.status === 1) {
      setFrom(1);
      localStorage.setItem("token", googleLoginResponse.token);
      setId(googleLoginResponse.data._id);
    }
  }, [googleLoginResponse]);

  useEffect(() => {

    if (id != null) {
      if (from == 0) {
        navigation("/Otp", { state: { id, fromScreen } });
        dispatch(clearDataSignUp());
      } else if (from == 1) {
        navigation("/signIn", { state: { fromScreen } });
        dispatch(clearDataSignUp());
      } else {
        navigation("/Project");
        dispatch(clearGoogleLoginData());
        dispatch(clearFacebookLoginData());
      }
    }
  }, [id]);


  return (
    <>
      <div className="inner">
        <div className="sec_bg">
          <div className="logotype">
            <img src={ai_img} alt="ai_img" />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>

        </div>
        <div className="signup_bg">
          <div className="text_btn">
            <h4>Already have an account?</h4>
            <Button
              onClick={() => {
                // sessionStorage.removeItem("signup_alert_shown");
                navigation(-1)
              }}
              style={{ cursor: "pointer" }}
            >
              Sign In
            </Button>
          </div>
          <div className="signup_form">
            <Form>
              <h3>Sign Up</h3>

              {/* Toggle Buttons */}
              <div className="toggle-btns">
                <Button

                  variant={inputMode === "phone" ? "primary" : "secondary"}
                  onClick={() => {

                    setaActive(0);
                    setInputMode("phone");
                  }}
                  style={{
                    backgroundColor: active == 0 ? "#fff" : "transparent",
                    width: "50%",
                  }}
                >
                  Phone
                </Button>
                <Button
                  variant={inputMode === "email" ? "primary" : "secondary"}
                  onClick={() => {

                    setaActive(1);
                    setInputMode("email");
                  }}
                  style={{
                    backgroundColor: active == 1 ? "#fff" : "transparent",
                    width: "50%",
                  }}
                >
                  Email
                </Button>
              </div>

              <Form.Group className="mb-3 form_group">
                {inputMode === "phone" ? (
                  <>
                    <Form.Label>Phone Number</Form.Label>
                    <div className="btn_flex mb-3">
                      <PhoneInput
                        inputClass={"inputt-ph"}
                        containerStyle={{}}
                        searchClass="search-class"
                        disableSearchIcon={false}
                        enableTerritories
                        countryCodeEditable={false}
                        placeholder="Phone Number"
                        buttonStyle={{ width: "47px" }}
                        dropdownStyle={{ height: "150px" }}
                        enableSearch={true}
                        value={country}
                        onChange={handleOnChange}
                        inputProps={{
                          readOnly: true,
                        }}
                        enableAreaCodeStretch
                        required
                      />
                      <Form.Control
                        type="number"
                        className="mobilenumber"
                        placeholder="Enter Mobile Number"
                        value={phnNumber}
                        onChange={(v) => setPhnNumber(v.target.value)}
                      />
                    </div>
                  </>
                ) : (
                  <>

                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      className="createInput"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ height: "44px", marginRight: "10px" }}
                    />
                    <Form.Group
                      className="mb-4 form-contain"
                      style={{ marginTop: 16 }}
                      controlId="formBasicPassword"
                    >
                      <Form.Label className="pass-label">Password</Form.Label>
                      <div className="inline">
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          className="createInput"
                          placeholder="Password"
                          name="Password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          required
                        />
                        <button
                          type="button"
                          className="passwod_btn"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? (
                            <VisibilityTwoTone />
                          ) : (
                            <VisibilityOffTwoTone />
                          )}
                        </button>
                      </div>
                    </Form.Group>

                    <Form.Group
                      className="mb-4 form-contain"
                      style={{ marginTop: 16 }}
                      controlId="formBasicPassword"
                    >
                      <Form.Label className="pass-label">Confirm Password</Form.Label>
                      <div className="inline">
                        <Form.Control
                          type={showConfirmPassword ? "text" : "password"}
                          className="createInput"
                          placeholder="Confirm Password"
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                          }}
                          required
                        />
                        <button
                          type="button"
                          className="passwod_btn"
                          onClick={() => {
                            setShowConfirmPassword(!showConfirmPassword);
                          }}
                        >
                          {showConfirmPassword ? (
                            <VisibilityTwoTone />
                          ) : (
                            <VisibilityOffTwoTone />
                          )}
                        </button>
                      </div>
                    </Form.Group>
                  </>
                )}
              </Form.Group>


              <div className="next_btn">
                <Button
                  onClick={() => onNextClick()}
                  style={{ cursor: "pointer" }}
                >
                  Next
                </Button>
                <img src={next_icon} alt="next_icon" />
              </div>

              <div className="separator">
                <span>or</span>
              </div>

              <div className="goog_fac_btn">
                <div
                  to="/"
                  className="button facebook"
                  style={{ cursor: "pointer" }}
                  onClick={() => login()}
                >
                  <img src={google} alt="google" /> Google
                </div>
                {/* <Link
                  to="/"
                  className="button facebook"
                  style={{ cursor: "pointer" }}
                >
                  <img src={facebook} alt="facebook" /> Facebook
                </Link> */}

                <div
                  className="button facebook"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleFacebookLogin()}
                >
                  <img src={facebook} alt="facebook" /> Facebook
                </div>

              </div>
            </Form>
          </div>


          <p>
            Protected by reCAPTCHA and subject to the Rhombus <br />
            <b>Privacy Policy</b> and <b>Terms of Service.</b>
          </p>
        </div>
      </div>
    </>
  );
};

export default SignUp;
